<template>
    <div style="width: 100%;background-color: #FFF;padding: 20px 0;">
      <ele-chart :option="visitHourChartOption" style="height:600px; width: 100%;" />
    </div>
  </template>
  
  <script>
	import EleChart from '@/components/EleChart'

  export default {
      name: "statisticsData",
      components: {
        EleChart,
      },
      data(){
          return{
            visitHourData: [], //折线图
          }
      },
      mounted() {
        this.getVisitHourData()
      },
      computed: {
        visitHourChartOption() {
          return {
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              data: ['发送总数量', '发送成功', '接收失败','发送失败'],
              // right: 20
            },
            xAxis: [{
              type: 'category',
              // boundaryGap: false,
              data: this.visitHourData.map(d => d.SendDate),
              axisLabel: {
                // rotate: 20,
                // margin: 15, // 与轴线距离
              }
            }],
            yAxis: [{
              type: 'value'
            }],
            color: ['#FFC53D', '#FD4D4F', '#36CFCA','#91CC75'],
            series: [{
              name: '发送成功',
              type: 'line',
              data: this.visitHourData.map(d => d.RespondedSuccessCount),
            }, {
              name: '发送总数量',
              type: 'line',
              data: this.visitHourData.map(d => d.TotalCount)
            }, {
              name: '接收失败',
              type: 'line',
              data: this.visitHourData.map(d => d.RespondedFailCount)
            }, {
              name: '发送失败',
              type: 'line',
              data: this.visitHourData.map(d => d.NoRespondedCount)
            }]
          }
        },
      },
      methods: {
        getVisitHourData(){
          this.$http.get('/sms/QuerySendStatistics').then(res => {
            console.log('折线图res',res);
            if (res.data.code == 0) {
              this.visitHourData = res.data.data;
              console.log('@@@', this.visitHourData)
            }
          })
        }
      }
  }
  </script>
  
  <style scoped>
  
  </style>